.p_20{
    padding: 20px;
}
.pb_10{
    padding-bottom: 10px;
}

.pb_13{
    padding-bottom: 13px !important;
}
.pb_14{
    padding-bottom: 14px !important;
}
.pb_15{
    padding-bottom: 15px !important;
    padding-top: 5px !important;
}
.pb_20{
    padding-bottom: 20px !important;
}

.pt_15{
    padding-top: 15px;
}
.pt_20{
    padding-top: 25px;
}
.pt_25{
    padding-top: 40px;
}

.p_t_b{
    padding: 0px 15px;
}
.ptb{
    padding: 0px 27px 20px;
}

.p_l_35{
    padding-left: 35px;
}

.p_l_t{
    padding-left: 55px;
    padding-top: 15px;
}

.p_l_t_30{
    padding-left: 30px;padding-top: 10px;
}

.border{
    border:1px solid #a3a3a3;padding: 10px;
    padding: 18px;
}

.required{
    color:red;
}

.submit_btn{
    padding-left: 30%;
    padding-top: 50px;
}

  .model_head{
    background-color: aliceblue;
  }
  .text_center{
    width: 20%;
    margin-left: 35%;
    margin-right: 35%;
  }

  .ReactModal__Overlay{
    background-color: rgba(0, 0, 0, 0.75) !important;
  }
  .flex{
      display: flex !important;
  }

  
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }
  .pagination > .active > a{
    background-color: #47ccde ;
    border-color: #47ccde ;
    color: #fff;
  }
  .pagination > li > a{
    border: 1px solid #47ccde ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #47ccde ;
    border-color: #47ccde;
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: #47ccde
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }
 