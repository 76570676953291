.f_left{
    float: left; 
}

.innerbox{
    min-height: 60px; padding:10px 0; background-color: #e9f1fb; 
    border-top:1px dashed #000; 
    border-bottom:1px dashed #000;
}

.mtop50{
    margin-top:50px;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #dce4af; 
    opacity: 1; 
  }

  .form-control {
    
    padding: .275rem .55rem !important;
    font-size: 0.8rem  !important;
    

  } 

  label{
      color:#293440 !important;
      margin-top: 3px; margin-bottom: 0.3rem;
  }

  .bbox{
     border: 1px dashed #ccc; 
     background: #e4eff1;  
  }

  .hide{
      display: none;
  }

  .show{
    display: '';
   }                                            

   .bgcls{
       background-color: #8c8792 !important;
       border-bottom: #8c8792 !important;
   }
   .p_2{
       padding:3px;
   }
   .w_20{
       width: 180px;
   }
   .w_5{
       width: 20px;
   }
   .prof{
       width: 50px;
       height: 50px;
       border-radius: 50%;
    -webkit-border-radius: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
   }

   .compbtn {
    border: none;
    /* transition: 0.5s; */
  }
  .compbtn:hover {border:2px solid #0084ff;}

  .compbr{
    border:2px solid #0084ff;
  }

  /* .lodemodel{
    border:unset !important;
    background-color:unset !important;
  } */

  
  .tail-datetime-calendar .calendar-date table tbody tr > * > span{
      z-index: unset !important;
  }

  .wrap{
    word-wrap: 'break-word';
  }

  .homecmp-container .webcam-container
{
display: flex;
flex-direction: column;
text-align: center;
padding:10px 0;
}
.homecmp-container .webcam-container .webcam-btn
{
margin-top: 10px;
width:30%;
}